import { NavigationProvider } from './lib/navigation/navigation';
import { RouterConfig } from './lib/navigation';
import { NavigationBar } from './components/NavigationBar';
import { Footer } from './components/Footer';

function App() {
  return (
    <div className="App">
      <NavigationProvider routerConfig={RouterConfig}>
        <NavigationBar />
      </NavigationProvider>
      <Footer />
    </div>
  );
}

export default App;
