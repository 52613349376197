import React from "react";
import { VideoBg } from "../../components/background/videobg";
import { Background } from "../../components/background";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faCircleCheck, faCode, faMicrochip } from "@fortawesome/free-solid-svg-icons";

export const Services: React.FC = () => {
    return (
        <div className="">
            <section className="video-container banner-container">
                <Background />
                <div className="video-caption text-light">
                    <h3 className="display-6">Servicios</h3>
                    <p className="text-brand">Nuestra misión es mantener a tu empresa a la vanguardia, ofreciendo tencología de punta y un servicio personalizado, enfocado en resolver problemas.</p>
                    <FontAwesomeIcon className="display-6 pt-5 mt-5 text-secondary" icon={faChevronDown} />
                </div>
            </section>

            <section className="me-0 bg-dark text-light">
                <div className="container row py-5">
                    <div className="col-8 p-5">
                        <div className="px-5">
                            <h3 className="display-6 pb-3">Transformación Digital <FontAwesomeIcon className="text-secondary" icon={faCode} /></h3>
                            <p className="lead">Nuestro servicio de transformación digital realiza un análisis detallado, estudio de casos, desarrollo e implementación de soluciones de software enfocados en incrementar eficiencia, abaratar recursos y mejorar la experiencia de sus clientes.</p>    
                        </div>
                    </div>
                    <div className="col-4 my-4 align-center">
                        <img src="/assets/undraw_progressive_app_m-9-ms.svg" width="450" className="p-5" alt="..."/>
                    </div>
                </div>
            </section>

            <section className="row me-0 bg-secondary-subtle">
                <div className="container row py-5">
                    <div className="col-4 my-4 d-flex">
                        <img height="250px" src="/assets/undraw_my_code_snippets_re_4adu.svg" className="mx-auto my-4" alt="..."/>
                    </div>
                    <div className="col-8 p-5">
                        <div className="px-5">
                            <h3 className="display-6 pb-3">Inteligencia Artificial <FontAwesomeIcon className="text-muted" icon={faMicrochip} /></h3>
                            <p className="lead">Somos especialistas en resolver problemas complejos. Desde un chat bot para ventas, hasta un sistema de predicción de comportamientos, su empresa tenrá el éxito asegurado.</p>    
                        </div>
                    </div>
                </div>
            </section>

            <section className="row me-0 bg-dark text-light">
                <div className="container row py-5">
                    <div className="col-8 p-5">
                        <h3 className="display-6 pb-3">Auditoría de IT <FontAwesomeIcon className="text-secondary" icon={faCircleCheck} /></h3>
                        <p className="lead">Diariamente cada empresa se enfrenta a diversos problemas en el área de IT, nuestro servicio se encarga de asegurar que su operación sea a prueba de fallas.</p>
                        <ul className="lead">
                            <li>Validación de procesos</li>
                            <li>Análisis de prácticas de seguridad</li>
                            <li>Reestructuración de procesos siguiendo las mejores prácticas</li>
                            <li>Capacitación de personal</li>
                            <li>Solución activa de fallas encontradas</li>
                        </ul>    
                    </div>
                    <div className="col-4 my-4 d-flex">
                        <img height="250" src="/assets/undraw_team_collaboration_re_ow29.svg" className="mx-auto my-4" alt="..."/>
                    </div>
                </div>
            </section>

            <section className="row me-0 bg-secondary-subtle">
                <div className="container row py-5">
                    <div className="col-4 my-4 d-flex">
                        <img height="200px" src="/assets/undraw_logistics_x-4-dc.svg" className="mx-auto my-4" alt="..."/>
                    </div>
                    <div className="col-8 p-5">
                        <div className="px-5">
                            <h3 className="display-6 pb-3">Administración SAP
                                <svg className="ps-3" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" height="70" viewBox="0,0,256,256">
                                    <g 
                                        fill="rgb(41, 109, 253)"
                                        fill-rule="nonzero"
                                        stroke="none"
                                        stroke-width="1"
                                        stroke-linecap="butt"
                                        stroke-linejoin="miter"
                                        stroke-miterlimit="10"
                                        stroke-dasharray=""
                                        stroke-dashoffset="0"
                                        font-family="none"
                                        font-weight="none"
                                        font-size="none"
                                        text-anchor="none"
                                    >
                                        <g 
                                            transform="scale(5.12,5.12)"
                                        >
                                            <path 
                                                d="M1,12v24h25l24,-24zM8.39844,18c3.40234,0 4.50391,0.80078 4.50391,0.80078c0,0 -0.60156,1.5 -0.90234,2.29688c-1,-0.39844 -5,-1.09766 -5,0.40234c0,0.60156 0.19922,1 3.19922,1.69922c1.19922,0.30078 3,0.90234 3.39844,2.70313l3.10156,-7.90234h3.5l3.80078,9.5v-9.5h4.5c2.5,0 4.5,2 4.5,4.5c0,2.5 -2,4.5 -4.5,4.5h-1.5v3h-5.5l-0.59766,-1.80078c-0.70312,0.30078 -1.50391,0.5 -2.40234,0.5c-0.89844,0 -1.69922,-0.19922 -2.40234,-0.5l-0.59766,1.80078h-3.5l0.5,-1.19922c-0.89844,0.69922 -2.30078,1.19922 -4.10156,1.19922c-3.29688,0 -4.39844,-0.90234 -4.39844,-0.90234l0.80078,-2.59766c1.89844,1.19922 5.29688,1 5.29688,0c0,-1 -1.49609,-1.09766 -3.49609,-1.69922c-1.90234,-0.60156 -2.60156,-2.10156 -2.60156,-3.39844c0,-1.30078 1,-3.40234 4.39844,-3.40234zM27,21v3h1.5c0.80078,0 1.5,-0.69922 1.5,-1.5c0,-0.80078 -0.69922,-1.5 -1.5,-1.5zM18.5,21.40234l-1.5,4.29688c0.39844,0.30078 0.89844,0.39844 1.5,0.39844c0.60156,0 1.10156,-0.09766 1.5,-0.39844z"
                                            ></path>
                                        </g>
                                    </g>
                                </svg>
                            </h3>
                            <p className="lead">Administre e integre su sistema de administración de recursos en la logística diaria de su empresa. Desde una entrada de inventario, hasta el timbrado de sus ventas. Su administración será completamente automatizada, somos líderes en administración de SAP y Business One.</p>    
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}