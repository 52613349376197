import React from "react";

export const UseCase: React.FC = () => {
    return(
        <div className="bg-dark text-light py-5">
            <div className="container py-5">
                <section className="row me-0 ps-5">
                    <div className="col">
                        <h3 className="display-6 pb-3">
                            Administración empresarial <br/> 
                            <span className="text-primary">en un solo click</span>
                        </h3>
                        <p className="lead pb-3">
                            Somos expertos en Inteligencia de Negocios, nuestros algoritmos de Minería de Datos, Inteligencia Artificial Predictiva e implementación organica con SAP serán las mejores herramientas para potenciar su empresa. 
                        </p>

                        <a href="https://calendly.com/black-key-systems/black-key-systems-project-talk" target="blank" className="btn btn-outline-primary">Platiquemos sobre su proyecto</a>
                    </div>
                    <div className="col-6 pe-0">
                        <img className="rounded float-end" src="/assets/mockup.png" width="100%" alt="System illustration"/>
                    </div>
                </section>
            </div>
        </div>
    );
}