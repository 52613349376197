import React from "react";

export const Customers: React.FC = () => {
    return(
        <section className="bg-banner text-light">
            <div className="banner-content text-center">
                <div className="container">
                    <div className="row mt-3 align-items-center justify-content-center">
                        <h3 className="pb-4">Experiencia en proyectos de alta escalabilidad</h3>
                        
                        <div className="col-6 col-sm-4 col-md-2 mb-md-0">
                            <div className="img-fluid text-gray-600 mb-md-0">
                                <img src="/assets/tailorie-logo.png" height="70px" alt="Volkswagen logo"/>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-12 col-md">
                            <div className="img-fluid text-gray-600 mb-md-0">
                                <img src="/assets/logo-wh.png" height="70px" alt="Volkswagen logo"/>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-12 col-md">
                            <div className="img-fluid text-gray-600 mb-md-0">
                                <img src="/assets/lm-logo-white.png" height="70px" alt="Liberty Mutual logo"/>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-12 col-md">
                            <div className="img-fluid text-gray-600 mb-md-0">
                                <img src="/assets/indeed-logo-white.png" height="50px" alt="Indeed logo"/>
                            </div>
                        </div>
                        
                       
                    </div>
                </div>
            </div>
        </section>
    );
}