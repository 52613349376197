import { createHashRouter, RouterProvider } from 'react-router-dom';
import { NavigationProviderProps } from './interfaces/navigation.interface';

export const NavigationProvider: React.FC<NavigationProviderProps> = ({
    children,
    routerConfig,
}) => {
    const router = createHashRouter(routerConfig);

    return (
        <>
            {children}
            <RouterProvider router={router} />
        </>
    );
};
