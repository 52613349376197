import React from 'react';
import { NavigationItemProps } from './interfaces/NavigationItemProps.interface';

export const NavigationItem: React.FC<NavigationItemProps> = ({
    path,
    label,
}) => (
    <li className='nav-item mx-3'>
        <a className='nav-link lead text-light' href={`#${path}`}>
            {label}
        </a>
    </li>
);
