import React from "react";

export const Benefits: React.FC = () => {
    return(
        <div className="bg-dark py-5 text-light">
            <div className="container p-5 mt-1">
                <div className="row">
                    <div className="col-12 col-md-4 aos-init aos-animate" data-aos="fade-up">
                        <div className="icon text-primary mb-3">
                            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <g fill="none" fill-rule="evenodd">
                                    <path d="M0 0h24v24H0z"></path>
                                    <path d="M7 3h10a4 4 0 110 8H7a4 4 0 110-8zm0 6a2 2 0 100-4 2 2 0 000 4z" fill="#296dfd"></path>
                                    <path d="M7 13h10a4 4 0 110 8H7a4 4 0 110-8zm10 6a2 2 0 100-4 2 2 0 000 4z" fill="#296dfd" opacity=".3"></path>
                                </g>
                            </svg>
                        </div>

                        <h3>Incrementa la satisfacción de sus clientes</h3>

                        <p className="mb-6 mb-md-0">
                        En un mercado creciente las expectativas de los clientes son cada vez mas altas, ofrecer servicios digitales y personalizados es la clave para una experiencia de primer nivel.
                        </p>
                    </div>

                    <div className="col-12 col-md-4 aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
                        <div className="icon text-primary mb-2">
                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" height="35" viewBox="0,0,256,256">
                                <g 
                                    fill="rgb(41, 109, 253)"
                                    fill-rule="nonzero"
                                    stroke="none"
                                    stroke-width="1"
                                    stroke-linecap="butt"
                                    stroke-linejoin="miter"
                                    stroke-miterlimit="10"
                                    stroke-dasharray=""
                                    stroke-dashoffset="0"
                                    font-family="none"
                                    font-weight="none"
                                    font-size="none"
                                    text-anchor="none"
                                >
                                    <g 
                                        transform="scale(5.12,5.12)"
                                    >
                                        <path 
                                            d="M1,12v24h25l24,-24zM8.39844,18c3.40234,0 4.50391,0.80078 4.50391,0.80078c0,0 -0.60156,1.5 -0.90234,2.29688c-1,-0.39844 -5,-1.09766 -5,0.40234c0,0.60156 0.19922,1 3.19922,1.69922c1.19922,0.30078 3,0.90234 3.39844,2.70313l3.10156,-7.90234h3.5l3.80078,9.5v-9.5h4.5c2.5,0 4.5,2 4.5,4.5c0,2.5 -2,4.5 -4.5,4.5h-1.5v3h-5.5l-0.59766,-1.80078c-0.70312,0.30078 -1.50391,0.5 -2.40234,0.5c-0.89844,0 -1.69922,-0.19922 -2.40234,-0.5l-0.59766,1.80078h-3.5l0.5,-1.19922c-0.89844,0.69922 -2.30078,1.19922 -4.10156,1.19922c-3.29688,0 -4.39844,-0.90234 -4.39844,-0.90234l0.80078,-2.59766c1.89844,1.19922 5.29688,1 5.29688,0c0,-1 -1.49609,-1.09766 -3.49609,-1.69922c-1.90234,-0.60156 -2.60156,-2.10156 -2.60156,-3.39844c0,-1.30078 1,-3.40234 4.39844,-3.40234zM27,21v3h1.5c0.80078,0 1.5,-0.69922 1.5,-1.5c0,-0.80078 -0.69922,-1.5 -1.5,-1.5zM18.5,21.40234l-1.5,4.29688c0.39844,0.30078 0.89844,0.39844 1.5,0.39844c0.60156,0 1.10156,-0.09766 1.5,-0.39844z"
                                        ></path>
                                    </g>
                                </g>
                            </svg>
                        </div>

                        <h3>SAP integrado en su logística</h3>

                        <p className="mb-0">
                            Llevar el control de sus inventarios desde la entrada de la mercancía hasta el timbrado en tan solo unos minutos es una realidad, a través de Business One.  
                        </p>
                    </div>

                    <div className="col-12 col-md-4 aos-init aos-animate" data-aos="fade-up" data-aos-delay="50">
                        <div className="icon text-primary mb-3">
                            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <g fill="none" fill-rule="evenodd">
                                    <path d="M0 0h24v24H0z"></path>
                                    <path 
                                        d="M5.5 4h4A1.5 1.5 0 0111 5.5v1A1.5 1.5 0 019.5 8h-4A1.5 1.5 0 014 6.5v-1A1.5 1.5 0 015.5 4zm9 12h4a1.5 1.5 0 011.5 1.5v1a1.5 1.5 0 01-1.5 1.5h-4a1.5 1.5 0 01-1.5-1.5v-1a1.5 1.5 0 011.5-1.5z"
                                        fill="#296dfd"
                                    ></path>
                                    <path
                                        d="M5.5 10h4a1.5 1.5 0 011.5 1.5v7A1.5 1.5 0 019.5 20h-4A1.5 1.5 0 014 18.5v-7A1.5 1.5 0 015.5 10zm9-6h4A1.5 1.5 0 0120 5.5v7a1.5 1.5 0 01-1.5 1.5h-4a1.5 1.5 0 01-1.5-1.5v-7A1.5 1.5 0 0114.5 4z"
                                        fill="#296dfd"
                                        opacity=".3"
                                    ></path>
                                </g>
                            </svg>
                        </div>

                        <h3>Reduce costos de producción</h3>

                        <p className="mb-6 mb-md-0">
                        Lleve una gestión optimizada de recursos y reduzca costos a través de la automatización de procesos y predicción de flujo de inventario.
                        </p>
                    </div>
                </div> 
            </div>
        </div>
    );
}