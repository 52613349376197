import React from "react";

export const Banner: React.FC = () => {
    return(
        <section className="hero">
            <div className="text-light text-center">                
                <h1 className="display-3">
                    Business Intelligence
                </h1>

                <p className="text-brand">
                    Desarrollamos Inteligencia Artificial y Data Mining para optimizar sus procesos e incrementar la productividad de su empresa.
                </p>

                <div className="text-center">
                    <a href="https://calendly.com/black-key-systems/black-key-systems-project-talk" className="btn btn-outline-light me-1" target="blank">
                        Iniciar transformación digital
                    </a>
                </div>
            </div>
        </section>
    );
};