import React from 'react';
import { NavigationItem } from './NavigationItem';
import { RouterConfig } from '../../lib/navigation';

export const NavigationBar: React.FC = () => {
    const createNavigationLinks = () =>
        RouterConfig.map(
            (routeItem, key) =>
                routeItem.listed && (
                    <NavigationItem
                        key={key}
                        path={routeItem.path}
                        label={routeItem.label}
                    />
                ),
        );

    return (
        <nav className='float-nav navbar navbar-expand-lg'>
            <div className='container px-md-5'>
                <a className="navbar-brand" href="#/">
                    <img
                        src='./assets/logo-light.png'
                        alt='Black Key Systems logo'
                        width='175'
                        className="d-inline-block align-text-top my-3"
                    />
                </a>
                
                <ul className='ms-5 navbar-nav me-auto'>
                    {createNavigationLinks()}
                </ul>

                <form className='d-flex'>
                    <a
                        href='mailto:gabriel.tello@blackkeysystems.com?subject=Solicitud de Cotizacion&body=Saludos, me interesa cotizar un proyecto con las siguientes característicask'
                        target='blank'
                        className='btn btn-outline-primary'
                    >
                        Cotizar Proyecto
                    </a>
                </form>
                
            </div>
        </nav>
    );
};
