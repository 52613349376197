import React from "react";

export const Footer: React.FC = () => {
    const supportNumber = "2224842621"
    const supportText = "Hola, me gustaría recibir mas información acerca de los servicios de Black Key Systems"

    return(
        <section className="hero-info pt-2 bg-black text-light">
            <div className="container p-5 text-center">
                <div className="mt-4 row align-items-center justify-content-center">
                    <p className="">Black Key Systems, 2023
                    <br/>Anáhuac I secc, Miguel Hidalgo, CDMX, México, 11320
                    <br/><a className="text-light" target="_blank" href={`https://wa.me/52${supportNumber}?text=${supportText}`}>{supportNumber}</a>
                    </p>
                </div>
            </div>
        </section>
    );
}