import React from "react";
import { Banner } from "./banner";
import { Benefits } from "./benefits";
import { Customers } from "./customers";
import { UseCase } from "./use-case";
import { Background } from "../../components/background";
import { VideoBg } from "../../components/background/videobg";

export const HomePage: React.FC =  () => {
    return (<>
        
        <VideoBg />
        <Banner />
        <Benefits />
        <Customers />
        <UseCase />
    </>);
};